import styled from "styled-components";

export const Imagem = styled.img`
  margin: 0 30px;
  padding: 0 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: no-repeat center/cover;
  width: 40vw;
  @media (max-width: 768px) {
    min-width: 250px;
  }
`;
