import { createSlice } from "@reduxjs/toolkit";
import { medicamentosRequest } from "../../actions/dashboards/dashboardsActions";

const medicamentosSlice = createSlice({
  name: "medicamentos",
  initialState: {
    medicamentos: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(medicamentosRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(medicamentosRequest.fulfilled, (state, action) => {
        state.medicamentos = action.payload;
        state.loading = false;
      })
      .addCase(medicamentosRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default medicamentosSlice.reducer;
