import { createSlice } from "@reduxjs/toolkit";
import { antecedentesPessoaisRequest } from "../../actions/dashboards/dashboardsActions";

const antecedentesPessoaisSlice = createSlice({
  name: "antecedentesPessoais",
  initialState: {
    antecedentesPessoais: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(antecedentesPessoaisRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(antecedentesPessoaisRequest.fulfilled, (state, action) => {
        state.antecedentesPessoais = action.payload;
        state.loading = false;
      })
      .addCase(antecedentesPessoaisRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default antecedentesPessoaisSlice.reducer;
