import { createSlice } from "@reduxjs/toolkit";
import { sintomasRequest } from "../../actions/dashboards/dashboardsActions";

const sintomasSlice = createSlice({
  name: "sintomas",
  initialState: {
    sintomas: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sintomasRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(sintomasRequest.fulfilled, (state, action) => {
        state.sintomas = action.payload;
        state.loading = false;
      })
      .addCase(sintomasRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default sintomasSlice.reducer;
