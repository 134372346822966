import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { BasicContainer } from "../../common/components/BasicContainer";
import { DynamicHeader } from "../../components/DynamicHeader";
import { BarChart } from "../../components/Chart/BarChart";
import { DoughnutChart } from "../../components/Chart/DoughnutChart";
import { PieChart } from "../../components/Chart/PieChart";

import {
  antecedentesPessoaisRequest,
  condicoesOptoRequest,
  consultasMesRequest,
  medicamentosRequest,
  sintomasRequest,
  tipoLenteRequest,
} from "../../redux/actions/dashboards/dashboardsActions";

const colorPalette = [
  "rgba(54, 162, 235, 0.6)",
  "rgba(255, 99, 132, 0.6)",
  "rgba(75, 192, 192, 0.6)",
  "rgba(255, 206, 86, 0.6)",
  "rgba(153, 102, 255, 0.6)",
  "rgba(255, 159, 64, 0.6)",
  "rgba(199, 199, 199, 0.6)",
  "rgba(83, 102, 255, 0.6)",
  "rgba(255, 205, 210, 0.6)",
  "rgba(0, 255, 127, 0.6)",
];

const antecedentesLabels = {
  cirurgia_catarata: "Cirurgia de Catarata",
  cirurgia_pterigio: "Cirurgia de Pterígio",
  trauma: "Trauma Ocular",
  outro: "Outros",
  nenhum: "Nenhum",
};

const condicoesOptoLabels = {
  astigmatismo: "Astigmatismo",
  hipermetropia: "Hipermetropia",
  miopia: "Miopia",
  presbiopia: "Presbiopia",
  emetropia: "Emetropia",
};

const medicamentosLabels = {
  ansiedade: "Ansiedade",
  colesterol: "Colesterol",
  coracao: "Coração",
  depressao: "Depressão",
  glicose: "Glicose",
  pa: "Pressão Alta",
  tireoide: "Tireoide",
  outro: "Outros",
  nenhum: "Nenhum",
};

const sintomasLabels = {
  ardencia: "Ardência",
  coceira: "Coceira",
  dificuldade_vp: "Dif. Visão Perto",
  dificuldade_vl: "Dif. Visão Longe",
  dor_de_cabeca: "Dor de Cabeça",
  dor_nos_olhos: "Dor nos Olhos",
  lacrimejamento: "Lacrimejamento",
  outro: "Outros",
  nenhum: "Nenhum",
};

const tipoLenteLabels = {
  monofocal: "Monofocal",
  bifocal: "Bifocal",
  multifocal: "Multifocal",
  nenhum: "Nenhum",
};

export const Dashboards = () => {
  const dispatch = useDispatch();
  const antecedentesPessoais = useSelector(
    (state) => state.antecedentesPessoaisReducer?.antecedentesPessoais
  );
  const condicoesOpto = useSelector(
    (state) => state.condicoesOptoReducer?.condicoesOpto
  );
  const consultasMes = useSelector(
    (state) => state.consultasMesReducer?.consultasMes
  );
  const medicamentos = useSelector(
    (state) => state.medicamentosReducer?.medicamentos
  );
  const sintomas = useSelector((state) => state.sintomasReducer?.sintomas);
  const tipoLente = useSelector((state) => state.tipoLenteReducer?.tipoLente);

  useEffect(() => {
    dispatch(antecedentesPessoaisRequest());
    dispatch(condicoesOptoRequest());
    dispatch(consultasMesRequest());
    dispatch(medicamentosRequest());
    dispatch(sintomasRequest());
    dispatch(tipoLenteRequest());
  }, [dispatch]);

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
      },
      tooltip: {
        callbacks: {
          title: function () {
            return "";
          },
          label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}%`,
        },
      },
    },
    scales: {
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function () {
            return "";
          },
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  // DADOS PARA BAR - CONSULTAS x MÊS
  const dataConsultasMes = useMemo(() => {
    if (consultasMes && consultasMes.length > 0) {
      const anos = [...new Set(consultasMes.map((item) => item.ano))];
      const colorOrder = [];
      for (let i = 0; anos.length > colorOrder.length; i++) {
        colorOrder.push(colorPalette[i % colorPalette.length]);
      }

      const datasets = anos.map((ano, index) => ({
        label: `${ano}`,
        data: consultasMes
          .filter((item) => item.ano === ano)
          .map((item) => item.quantidade),
        backgroundColor: colorOrder[index],
        borderColor: colorOrder[index].replace("0.6", "1"),
        borderWidth: 1,
      }));

      return {
        labels: consultasMes
          .filter((item) => item.ano === anos[0])
          .map((item) => item.mes),
        datasets,
      };
    }
    return null;
  }, [consultasMes]);

  const specificOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: function () {
            return ""; // Não retorna nada para o título
          },
          label: function (tooltipItem) {
            const datasetIndex = tooltipItem.datasetIndex; // Índice do conjunto de dados
            const index = tooltipItem.dataIndex; // Índice do item dentro do conjunto de dados
            const mes = dataConsultasMes.labels[index]; // Acessa os labels do dataset que são comuns a todos os datasets
            const quantidade = tooltipItem.raw;
            const ano = dataConsultasMes.datasets[datasetIndex].label; // Acessa o label do dataset que corresponde ao ano
            return `${mes} ${ano}: ${quantidade}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  // DADOS PARA DOUGHNUT - ANTECEDENTES
  const dataAntecedentesPessoais = useMemo(() => {
    if (antecedentesPessoais && Object.keys(antecedentesPessoais).length > 0) {
      const total = Object.values(antecedentesPessoais).reduce(
        (acc, value) => acc + parseInt(value, 10),
        0
      );
      return {
        labels: Object.keys(antecedentesPessoais).map(
          (key) => antecedentesLabels[key]
        ),
        datasets: [
          {
            data: Object.values(antecedentesPessoais).map((value) =>
              ((parseInt(value, 10) / total) * 100).toFixed(2)
            ),
            backgroundColor: colorPalette.slice(
              0,
              Object.keys(antecedentesPessoais).length
            ),
            hoverBackgroundColor: colorPalette
              .slice(0, Object.keys(antecedentesPessoais).length)
              .map((color) => color.replace("0.6", "0.8")),
          },
        ],
      };
    }
    return null;
  }, [antecedentesPessoais]);

  // DADOS PARA DOUGHNUT - CONDIÇÕES OFTALMOLÓGICAS
  const dataCondicoesOpto = useMemo(() => {
    if (condicoesOpto && Object.keys(condicoesOpto).length > 0) {
      const total = Object.values(condicoesOpto).reduce(
        (acc, value) => acc + parseInt(value, 10),
        0
      );
      return {
        labels: Object.keys(condicoesOpto).map(
          (key) => condicoesOptoLabels[key]
        ),
        datasets: [
          {
            data: Object.values(condicoesOpto).map((value) =>
              ((parseInt(value, 10) / total) * 100).toFixed(2)
            ),
            backgroundColor: colorPalette.slice(
              0,
              Object.keys(condicoesOpto).length
            ),
            hoverBackgroundColor: colorPalette
              .slice(0, Object.keys(condicoesOpto).length)
              .map((color) => color.replace("0.6", "0.8")),
          },
        ],
      };
    }
    return null;
  }, [condicoesOpto]);

  // DADOS PARA PIE - MEDICAMENTOS
  const dataMedicamentos = useMemo(() => {
    if (medicamentos && Object.keys(medicamentos).length > 0) {
      const total = Object.values(medicamentos).reduce(
        (acc, value) => acc + parseInt(value, 10),
        0
      );
      return {
        labels: Object.keys(medicamentos).map((key) => medicamentosLabels[key]),
        datasets: [
          {
            data: Object.values(medicamentos).map((value) =>
              ((parseInt(value, 10) / total) * 100).toFixed(2)
            ),
            backgroundColor: colorPalette.slice(
              0,
              Object.keys(medicamentos).length
            ),
            hoverBackgroundColor: colorPalette
              .slice(0, Object.keys(medicamentos).length)
              .map((color) => color.replace("0.6", "0.8")),
          },
        ],
      };
    }
    return null;
  }, [medicamentos]);

  // DADOS PARA PIE - SINTOMAS
  const dataSintomas = useMemo(() => {
    if (sintomas && Object.keys(sintomas).length > 0) {
      const total = Object.values(sintomas).reduce(
        (acc, value) => acc + parseInt(value, 10),
        0
      );
      return {
        labels: Object.keys(sintomas).map((key) => sintomasLabels[key]),
        datasets: [
          {
            data: Object.values(sintomas).map((value) =>
              ((parseInt(value, 10) / total) * 100).toFixed(2)
            ),
            backgroundColor: colorPalette.slice(
              0,
              Object.keys(sintomas).length
            ),
            hoverBackgroundColor: colorPalette
              .slice(0, Object.keys(sintomas).length)
              .map((color) => color.replace("0.6", "0.8")),
          },
        ],
      };
    }
    return null;
  }, [sintomas]);

  // DADOS PARA BAR - TIPO LENTE
  const dataTipoLente = useMemo(() => {
    if (tipoLente && Object.keys(tipoLente).length > 0) {
      return {
        labels: Object.keys(tipoLente).map((key) => tipoLenteLabels[key]),
        datasets: [
          {
            label: "Tipos de Lente",
            data: Object.values(tipoLente),
            backgroundColor: colorPalette[0],
            borderColor: colorPalette[0].replace("0.6", "1"),
            borderWidth: 1,
          },
        ],
      };
    }
    return null;
  }, [tipoLente]);

  return (
    <>
      <DynamicHeader title={"Dashboards"} visibility={false} />
      <BasicContainer>
        <Grid container spacing={2}>
          {dataConsultasMes && (
            <BarChart
              title="Consultas x Mês"
              data={dataConsultasMes}
              options={specificOptions}
            />
          )}
          {dataMedicamentos && (
            <PieChart
              title="Distribuição de Medicamentos (%)"
              data={dataMedicamentos}
              options={pieOptions}
            />
          )}
          {dataSintomas && (
            <PieChart
              title="Distribuição de Sintomas (%)"
              data={dataSintomas}
              options={pieOptions}
            />
          )}
          {dataAntecedentesPessoais && (
            <DoughnutChart
              title="Antecedentes Pessoais (%)"
              data={dataAntecedentesPessoais}
              options={pieOptions}
            />
          )}
          {dataTipoLente && (
            <BarChart
              title="Tipos de Lente"
              data={dataTipoLente}
              options={barOptions}
            />
          )}
          {dataCondicoesOpto && (
            <DoughnutChart
              title="Condições Optométricas (%)"
              data={dataCondicoesOpto}
              options={pieOptions}
            />
          )}
        </Grid>
      </BasicContainer>
    </>
  );
};
