import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Grid,
  TextField,
  Button,
  Autocomplete,
  Switch,
  FormControlLabel,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
} from "@mui/material";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import { DynamicHeader } from "../../DynamicHeader";
import { FormContainer } from "../../../common/components/FormContainer";
import { Notification } from "../../../common/components/Notification";
import { pacientesRequest } from "../../../redux/actions/sistema/pacientesActions";
import { addPaciente, addConsulta } from "../../../services/api";
import { tiposGenero } from "../../../functions/static_data";
import { decryptUser } from "../../../services/auth";
import { formatTelefone } from "../../../functions/formatters";

const steps = [
  "Identificação Pessoal",
  "Questionário",
  "Biomicroscopia",
  "Ceratometria e Retinoscopia",
  "RX Final",
  "Resumo Clínico",
];

const calculateAge = (birthDate) => {
  const today = new Date();
  const [year, month, day] = birthDate.split("-").map(Number);
  const birthDateObj = new Date(year, month - 1, day);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const currentDate = today.getDate();

  if (currentMonth < month || (currentMonth === month && currentDate < day)) {
    age--;
  }

  return age;
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg, #63aeda 0%, #1283b8 50%, #0e6b97 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg, #63aeda 0%, #1283b8 50%, #0e6b97 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #63aeda 0%, #1283b8 50%, #0e6b97 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #63aeda 0%, #1283b8 50%, #0e6b97 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, icon } = props;

  const icons = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

export const FormConsulta = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = decryptUser();
  const pacientes = useSelector((state) => state.pacientesReducer.pacientes);
  const [activeStep, setActiveStep] = useState(0);
  const [agendamento, setAgendamento] = useState(null);
  const [paciente, setPaciente] = useState(null);
  const [idade, setIdade] = useState("");
  const [telefone, setTelefone] = useState("");
  const [profissao, setProfissao] = useState("");
  const [endereco, setEndereco] = useState("");
  const [cidade, setCidade] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");

  const [medicamentos, setMedicamentos] = useState({
    Ansiedade: false,
    Colesterol: false,
    Coracao: false,
    Depressao: false,
    Glicose: false,
    PA: false,
    Tireoide: false,
    Outro: false,
    nomeOutro: "",
  });
  const [sintomas, setSintomas] = useState({
    Ardencia: false,
    Coceira: false,
    DifVP: false,
    DifVL: false,
    DorDeCabeca: false,
    DorNosOlhos: false,
    Lacrimejamento: false,
    Outro: false,
    nomeOutro: "",
  });
  const [antecedentes, setAntecedentes] = useState({
    CirurgiaCatarata: false,
    CirurgiaPterigio: false,
    Trauma: false,
    Outro: false,
    nomeOutro: "",
  });
  const [rxUsuario, setRxUsuario] = useState({
    Oculos: false,
    LCG: false,
  });
  const [rxDetalhes, setRxDetalhes] = useState({
    OD: { Esferico: "", Cilindrico: "", Eixo: "", AV: "" },
    OE: { Esferico: "", Cilindrico: "", Eixo: "", AV: "" },
    ADD: { Esferico: "", Cilindrico: "", Eixo: "", AV: "" },
    tipoLente: "",
  });
  const [biomicroscopia, setBiomicroscopia] = useState({
    Sobrancelha: "",
    Cilios: "",
    Palpebras: "",
    Conjuntivas: "",
    Corneas: "",
    Iris: "",
    Observacoes: "",
  });
  const [ceratometria, setCeratometria] = useState({
    OD: "",
    OE: "",
    Observacoes: "",
  });
  const [retinoscopia, setRetinoscopia] = useState({
    OD: { Esferico: "", Cilindrico: "", Eixo: "" },
    OE: { Esferico: "", Cilindrico: "", Eixo: "" },
  });
  const [rxFinal, setRxFinal] = useState({
    OD: { Esferico: "", Cilindrico: "", Eixo: "", AVL: "", AVP: "" },
    OE: { Esferico: "", Cilindrico: "", Eixo: "", AVL: "", AVP: "" },
    ADD: "",
    Monofocal: false,
    Bifocal: false,
    Multifocal: false,
  });
  const [resumoClinico, setResumoClinico] = useState({
    Texto: "",
    Astigmatismo: false,
    Hipermetropia: false,
    Miopia: false,
    Presbiopia: false,
  });
  const [notification, setNotification] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [dataNascimento, setDataNascimento] = useState(null);
  const [cpf, setCpf] = useState("");
  const [genero, setGenero] = useState("");
  const [uf, setUf] = useState("");
  const [isNewConsult, setIsNewConsult] = useState(false);
  const [isNewPatient, setIsNewPatient] = useState(false);

  const [showMedicamentos, setShowMedicamentos] = useState(false);
  const [showSintomas, setShowSintomas] = useState(false);
  const [showAntecedentes, setShowAntecedentes] = useState(false);
  const [showRx, setShowRx] = useState(false);

  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
    setTimeout(() => {
      setNotification({ message: "", type: "", visible: false });
    }, 4000);
  };

  useEffect(() => {
    dispatch(pacientesRequest());
  }, [dispatch]);

  useEffect(() => {
    const event = location.state?.event;
    if (event) {
      setAgendamento(event.id);
      setTelefone(event.telefone || "");
      if (event.paciente_id) {
        const paciente = pacientes.find((p) => p.id === event.paciente_id);
        if (paciente) {
          setPaciente(paciente);
          setIdade(calculateAge(paciente.data_nascimento));
          setTelefone(paciente.telefone);
          setProfissao(paciente.profissao);
          setEndereco(paciente.endereco);
          setCidade(paciente.cidade);
        }
      } else {
        setNome(event.nome_temporario || "");
        setDataNascimento(null);
        setIsNewPatient(true);
      }
    } else {
      setIsNewConsult(true);
    }
  }, [location.state, pacientes]);

  useEffect(() => {
    if (dataNascimento) {
      setIdade(calculateAge(dataNascimento));
    } else {
      setIdade("");
    }
  }, [dataNascimento]);

  useEffect(() => {
    if (isNewPatient) {
      setPaciente(null);
      setIdade("");
      setTelefone("");
      setProfissao("");
      setEndereco("");
      setCidade("");
      setDataNascimento(null);
    }
  }, [isNewPatient]);

  const removeMask = (value) => {
    return value.replace(/\D/g, "");
  };

  const handleNextPaciente = async () => {
    if (!paciente && !isNewPatient) {
      showNotification(
        "Selecione ou cadastre um paciente antes de prosseguir.",
        "error"
      );
      return;
    }
    if (isNewPatient) {
      const camposObrigatorios = {
        nome: "Nome",
        sobrenome: "Sobrenome",
        dataNascimento: "Data de Nascimento",
        telefone: "Telefone",
      };

      const erros = [];
      if (nome === "") erros.push(camposObrigatorios.nome);
      if (sobrenome === "") erros.push(camposObrigatorios.sobrenome);
      if (dataNascimento === null)
        erros.push(camposObrigatorios.dataNascimento);
      if (telefone === "") erros.push(camposObrigatorios.telefone);

      if (erros.length === 0) {
        try {
          const data = {
            nome,
            sobrenome,
            data_nascimento: dataNascimento,
            genero,
            cpf: removeMask(cpf),
            telefone: removeMask(telefone),
            endereco,
            cidade,
            uf,
            profissao,
          };

          let res = await addPaciente(data);

          if (res.status === 200) {
            const novoPacienteId = res.data.id;

            showNotification("Paciente criado com sucesso.", "success");
            dispatch(pacientesRequest());
            const pacienteAtualizado = {
              id: novoPacienteId,
              nome: data.nome,
              sobrenome: data.sobrenome,
              data_nascimento: data.data_nascimento,
              telefone: data.telefone,
              profissao: data.profissao,
              endereco: data.endereco,
              cidade: data.cidade,
              uf: data.uf,
            };

            setPaciente(pacienteAtualizado);
            setIdade(calculateAge(data.data_nascimento));
            setTelefone(data.telefone);
            setProfissao(data.profissao);
            setEndereco(data.endereco);
            setCidade(data.cidade);
            setIsNewPatient(false);
          }
        } catch (error) {
          showNotification(error.response?.data?.error, "error");
          return;
        }
      } else {
        showNotification(
          `Os seguintes campos são obrigatórios: ${erros.join(", ")}`,
          "error"
        );
        return;
      }
    }
    handleNext();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePacienteChange = (event, value) => {
    setPaciente(value);
    if (value) {
      setIdade(calculateAge(value.data_nascimento));
      setTelefone(value.telefone);
      setProfissao(value.profissao);
      setEndereco(value.endereco);
      setCidade(value.cidade);
    } else {
      setIdade("");
      setTelefone("");
      setProfissao("");
      setEndereco("");
      setCidade("");
    }
  };

  const handleMedicamentosChange = (event) => {
    setMedicamentos({
      ...medicamentos,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSintomasChange = (event) => {
    setSintomas({
      ...sintomas,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAntecedentesChange = (event) => {
    setAntecedentes({
      ...antecedentes,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRxChange = (event) => {
    setRxUsuario({
      ...rxUsuario,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRxDetalhesChange = (event) => {
    const [field, subfield] = event.target.name.split(".");
    setRxDetalhes({
      ...rxDetalhes,
      [field]: {
        ...rxDetalhes[field],
        [subfield]: event.target.value,
      },
    });
  };

  const handleBiomicroscopiaChange = (event) => {
    setBiomicroscopia({
      ...biomicroscopia,
      [event.target.name]: event.target.value,
    });
  };

  const handleCeratometriaChange = (event) => {
    setCeratometria({
      ...ceratometria,
      [event.target.name]: event.target.value,
    });
  };

  const handleRetinoscopiaChange = (event) => {
    const [field, subfield] = event.target.name.split(".");
    setRetinoscopia({
      ...retinoscopia,
      [field]: {
        ...retinoscopia[field],
        [subfield]: event.target.value,
      },
    });
  };

  const handleRxFinalSwitch = (event) => {
    const { name, checked } = event.target;
    setRxFinal((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleRxFinalDetalhesChange = (event) => {
    const [field, subfield] = event.target.name.split(".");
    setRxFinal((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [subfield]: event.target.value,
      },
    }));
  };

  const handleResumoClinicoSwitch = (event) => {
    const { name, checked } = event.target;
    setResumoClinico((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleResumoClinicoTexto = (event) => {
    setResumoClinico((prev) => ({
      ...prev,
      Texto: event.target.value,
    }));
  };

  async function handleSubmit() {
    try {
      const consultaData = {
        agendamento_id: agendamento,
        paciente_id: paciente.id,
        usuario_id: user.id,
        medicamentos,
        sintomas,
        antecedentes,
        rxUsuario,
        rxDetalhes,
        biomicroscopia,
        ceratometria,
        retinoscopia,
        rxFinal,
        resumoClinico,
      };

      const consultaResponse = await addConsulta(consultaData);

      if (consultaResponse.status === 201) {
        showNotification(
          "Consulta e detalhes da consulta salvos com sucesso!",
          "success"
        );
        handleClose();
      } else {
        throw new Error("Erro ao salvar a consulta");
      }
    } catch (error) {
      showNotification(error.message, "error");
    }
  }

  const handleClose = () => {
    navigate("/");
  };

  return (
    <>
      <DynamicHeader title={"Consulta"} visibility={false} />
      <FormContainer>
        <Box sx={{ width: "100%" }}>
          {isMobile ? (
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              <Step>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  icon={activeStep + 1}
                >
                  {steps[activeStep]}
                </StepLabel>
              </Step>
            </Stepper>
          ) : (
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          )}

          <Box sx={{ mt: 2 }}>
            {activeStep === 0 && (
              <>
                {isNewConsult && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isNewPatient}
                        onChange={() => setIsNewPatient((prev) => !prev)}
                      />
                    }
                    label="Cadastrar novo paciente"
                  />
                )}
                {paciente || (isNewConsult && !isNewPatient) ? (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={9}>
                      <Autocomplete
                        id="paciente"
                        fullWidth
                        required
                        options={pacientes}
                        getOptionLabel={(option) =>
                          `${option.nome} ${option.sobrenome}`
                        }
                        value={paciente}
                        onChange={handlePacienteChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Paciente"
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        label="Idade"
                        variant="standard"
                        fullWidth
                        disabled
                        value={idade}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Telefone"
                        variant="standard"
                        fullWidth
                        disabled
                        value={formatTelefone(telefone)}
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Profissão"
                        variant="standard"
                        fullWidth
                        disabled
                        value={profissao}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Endereço"
                        variant="standard"
                        fullWidth
                        disabled
                        value={endereco}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Cidade"
                        variant="standard"
                        fullWidth
                        disabled
                        value={cidade}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        size="small"
                        sx={{ width: 95 }}
                        color="error"
                      >
                        Cancelar
                      </Button>
                      <Button
                        onClick={handleNextPaciente}
                        variant="contained"
                        size="small"
                        sx={{ width: 95, ml: 2 }}
                        color="primary"
                      >
                        Próximo
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="nome"
                        name="nome"
                        label="Nome"
                        autoComplete="nome"
                        variant="standard"
                        fullWidth
                        required
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="sobrenome"
                        name="sobrenome"
                        label="Sobrenome"
                        autoComplete="sobrenome"
                        variant="standard"
                        fullWidth
                        required
                        value={sobrenome}
                        onChange={(e) => setSobrenome(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        label="Data de Nascimento"
                        variant="standard"
                        type="date"
                        fullWidth
                        required
                        value={dataNascimento}
                        onChange={(e) => setDataNascimento(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        label="Idade"
                        variant="standard"
                        fullWidth
                        disabled
                        value={idade}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputMask
                        mask="999.999.999-99"
                        value={cpf}
                        onChange={(e) => setCpf(e.target.value)}
                      >
                        {() => (
                          <TextField
                            id="cpf"
                            name="cpf"
                            label="CPF"
                            variant="standard"
                            fullWidth
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputMask
                        mask="(99) 99999-9999"
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                      >
                        {() => (
                          <TextField
                            id="telefone"
                            name="telefone"
                            label="Telefone"
                            variant="standard"
                            fullWidth
                            required
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        id="genero"
                        fullWidth
                        options={tiposGenero()}
                        getOptionLabel={(option) => option.nome}
                        defaultValue={
                          tiposGenero().find(
                            (tipoGenero) => tipoGenero.id === genero
                          ) || null
                        }
                        isOptionEqualToValue={(option, value) =>
                          value === null || option.nome === value.nome
                        }
                        onChange={(e, value) => setGenero(value.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Gênero"
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="endereco"
                        name="endereco"
                        label="Endereço"
                        variant="standard"
                        fullWidth
                        value={endereco}
                        onChange={(e) => setEndereco(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="cidade"
                        name="cidade"
                        label="Cidade"
                        variant="standard"
                        fullWidth
                        value={cidade}
                        onChange={(e) => setCidade(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="uf"
                        name="uf"
                        label="UF"
                        variant="standard"
                        fullWidth
                        value={uf}
                        onChange={(e) => setUf(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="profissao"
                        name="profissao"
                        label="Profissão"
                        variant="standard"
                        fullWidth
                        value={profissao}
                        onChange={(e) => setProfissao(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        size="small"
                        sx={{ width: 95 }}
                        color="error"
                      >
                        Cancelar
                      </Button>

                      <Button
                        onClick={handleNextPaciente}
                        variant="contained"
                        size="small"
                        sx={{ width: 95, ml: 2 }}
                        color="primary"
                      >
                        Próximo
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {activeStep === 1 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6">Medicamentos</Typography>
                    <Switch
                      checked={showMedicamentos}
                      onChange={() => setShowMedicamentos(!showMedicamentos)}
                    />
                  </Box>
                  {showMedicamentos && (
                    <>
                      {[
                        "Ansiedade",
                        "Colesterol",
                        "Coracao",
                        "Depressao",
                        "Glicose",
                        "PA",
                        "Tireoide",
                        "Outro",
                      ].map((med) => (
                        <FormControlLabel
                          key={med}
                          control={
                            <Switch
                              checked={medicamentos[med]}
                              onChange={handleMedicamentosChange}
                              name={med}
                            />
                          }
                          label={med}
                        />
                      ))}
                      {medicamentos.Outro && (
                        <TextField
                          label="Nome do medicamento"
                          variant="standard"
                          fullWidth
                          sx={{ mt: 1 }}
                          value={medicamentos.nomeOutro}
                          onChange={(e) =>
                            setMedicamentos({
                              ...medicamentos,
                              nomeOutro: e.target.value,
                            })
                          }
                        />
                      )}
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6">Sintomas</Typography>
                    <Switch
                      checked={showSintomas}
                      onChange={() => setShowSintomas(!showSintomas)}
                    />
                  </Box>
                  {showSintomas && (
                    <>
                      {[
                        "Ardencia",
                        "Coceira",
                        "DifVP",
                        "DifVL",
                        "DorDeCabeca",
                        "DorNosOlhos",
                        "Lacrimejamento",
                        "Outro",
                      ].map((sintoma) => (
                        <FormControlLabel
                          key={sintoma}
                          control={
                            <Switch
                              checked={sintomas[sintoma]}
                              onChange={handleSintomasChange}
                              name={sintoma}
                            />
                          }
                          label={sintoma.replace(/([A-Z])/g, " $1").trim()}
                        />
                      ))}
                      {sintomas.Outro && (
                        <TextField
                          label="Descrição do sintoma"
                          variant="standard"
                          fullWidth
                          sx={{ mt: 1 }}
                          value={sintomas.nomeOutro}
                          onChange={(e) =>
                            setSintomas({
                              ...sintomas,
                              nomeOutro: e.target.value,
                            })
                          }
                        />
                      )}
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6">Antecedentes Pessoais</Typography>
                    <Switch
                      checked={showAntecedentes}
                      onChange={() => setShowAntecedentes(!showAntecedentes)}
                    />
                  </Box>
                  {showAntecedentes && (
                    <>
                      {[
                        "CirurgiaCatarata",
                        "CirurgiaPterigio",
                        "Trauma",
                        "Outro",
                      ].map((antecedente) => (
                        <FormControlLabel
                          key={antecedente}
                          control={
                            <Switch
                              checked={antecedentes[antecedente]}
                              onChange={handleAntecedentesChange}
                              name={antecedente}
                            />
                          }
                          label={antecedente.replace(/([A-Z])/g, " $1").trim()}
                        />
                      ))}
                      {antecedentes.Outro && (
                        <TextField
                          label="Descrição do antecedente"
                          variant="standard"
                          fullWidth
                          sx={{ mt: 1 }}
                          value={antecedentes.nomeOutro}
                          onChange={(e) =>
                            setAntecedentes({
                              ...antecedentes,
                              nomeOutro: e.target.value,
                            })
                          }
                        />
                      )}
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6">Usuário de RX</Typography>
                    <Switch
                      checked={showRx}
                      onChange={() => setShowRx(!showRx)}
                    />
                  </Box>
                  {showRx && (
                    <>
                      {["Oculos", "LCG"].map((rx) => (
                        <FormControlLabel
                          key={rx}
                          control={
                            <Switch
                              checked={rxUsuario[rx]}
                              onChange={handleRxChange}
                              name={rx}
                            />
                          }
                          label={rx}
                        />
                      ))}
                      {rxUsuario && (
                        <Box sx={{ ml: 3 }}>
                          <Table>
                            <TableBody>
                              {["OD", "OE", "ADD"].map((campo) => (
                                <TableRow key={campo}>
                                  <TableCell>{campo}</TableCell>
                                  {["Esferico", "Cilindrico", "Eixo", "AV"].map(
                                    (coluna) => (
                                      <TableCell key={coluna}>
                                        <TextField
                                          variant="standard"
                                          value={rxDetalhes[campo][coluna]}
                                          onChange={handleRxDetalhesChange}
                                          name={`${campo}.${coluna}`}
                                          label={coluna}
                                        />
                                      </TableCell>
                                    )
                                  )}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <TextField
                            label="Tipo Lente"
                            variant="standard"
                            fullWidth
                            sx={{ mt: 2 }}
                            value={rxDetalhes.tipoLente}
                            onChange={(e) =>
                              setRxDetalhes({
                                ...rxDetalhes,
                                tipoLente: e.target.value,
                              })
                            }
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={handleBack}
                    variant="contained"
                    size="small"
                    sx={{ width: 95 }}
                    color="primary"
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    size="small"
                    sx={{ width: 95, ml: 2 }}
                    color="primary"
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            )}
            {activeStep === 2 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6">Biomicroscopia</Typography>
                </Grid>
                {[
                  "Sobrancelha",
                  "Cilios",
                  "Palpebras",
                  "Conjuntivas",
                  "Corneas",
                  "Iris",
                ].map((field) => (
                  <Grid item xs={12} sm={6} key={field}>
                    <TextField
                      label={field}
                      variant="standard"
                      fullWidth
                      value={biomicroscopia[field]}
                      onChange={handleBiomicroscopiaChange}
                      name={field}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Observações"
                    variant="standard"
                    fullWidth
                    multiline
                    maxRows={4}
                    value={biomicroscopia.Observacoes}
                    onChange={handleBiomicroscopiaChange}
                    name="Observacoes"
                    sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleBack}
                    variant="contained"
                    size="small"
                    sx={{ width: 95 }}
                    color="primary"
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    size="small"
                    sx={{ width: 95, ml: 2 }}
                    color="primary"
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            )}

            {activeStep === 3 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Ceratometria</Typography>
                  {["OD", "OE", "Observacoes"].map((field) => (
                    <TextField
                      key={field}
                      label={field}
                      variant="standard"
                      fullWidth
                      value={ceratometria[field]}
                      onChange={handleCeratometriaChange}
                      name={field}
                      sx={{ mb: 2 }}
                    />
                  ))}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">Retinoscopia</Typography>
                  <Table>
                    <TableBody>
                      {["OD", "OE"].map((campo) => (
                        <TableRow key={campo}>
                          <TableCell>{campo}</TableCell>
                          {["Esferico", "Cilindrico", "Eixo"].map((coluna) => (
                            <TableCell key={coluna}>
                              <TextField
                                variant="standard"
                                value={retinoscopia[campo][coluna]}
                                onChange={handleRetinoscopiaChange}
                                name={`${campo}.${coluna}`}
                                label={coluna}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={handleBack}
                    variant="contained"
                    size="small"
                    sx={{ width: 95 }}
                    color="primary"
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    size="small"
                    sx={{ width: 95, ml: 2 }}
                    color="primary"
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            )}
            {activeStep === 4 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">RX Final</Typography>
                  <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                    {["Monofocal", "Bifocal", "Multifocal"].map((type) => (
                      <FormControlLabel
                        key={type}
                        control={
                          <Switch
                            checked={rxFinal[type]}
                            onChange={handleRxFinalSwitch}
                            name={type}
                          />
                        }
                        label={type.charAt(0).toUpperCase() + type.slice(1)}
                      />
                    ))}
                  </Box>
                  <Table>
                    <TableBody>
                      {["OD", "OE"].map((campo) => (
                        <TableRow key={campo}>
                          <TableCell>{campo}</TableCell>
                          {["Esferico", "Cilindrico", "Eixo", "AVL", "AVP"].map(
                            (coluna) => (
                              <TableCell key={coluna}>
                                <TextField
                                  variant="standard"
                                  value={rxFinal[campo][coluna]}
                                  onChange={handleRxFinalDetalhesChange}
                                  name={`${campo}.${coluna}`}
                                  label={coluna}
                                />
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TextField
                    label="ADD"
                    variant="standard"
                    fullWidth
                    sx={{ mt: 2 }}
                    value={rxFinal.ADD}
                    onChange={(e) =>
                      setRxFinal({ ...rxFinal, ADD: e.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={handleBack}
                    variant="contained"
                    size="small"
                    sx={{ width: 95 }}
                    color="primary"
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    size="small"
                    sx={{ width: 95, ml: 2 }}
                    color="primary"
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            )}
            {activeStep === 5 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Resumo Clínico</Typography>
                  <Box sx={{ display: "flex", gap: 2, mt: 2, mb: 2 }}>
                    {[
                      "Astigmatismo",
                      "Hipermetropia",
                      "Miopia",
                      "Presbiopia",
                    ].map((condition) => (
                      <FormControlLabel
                        key={condition}
                        control={
                          <Switch
                            checked={resumoClinico[condition]}
                            onChange={handleResumoClinicoSwitch}
                            name={condition}
                          />
                        }
                        label={
                          condition.charAt(0).toUpperCase() + condition.slice(1)
                        }
                      />
                    ))}
                  </Box>
                  <TextField
                    fullWidth
                    multiline
                    rows={6}
                    value={resumoClinico.Texto}
                    onChange={handleResumoClinicoTexto}
                    label="Detalhes"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={handleBack}
                    variant="contained"
                    size="small"
                    sx={{ width: 95 }}
                    color="primary"
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    size="small"
                    sx={{ width: 95, ml: 2 }}
                    color="success"
                  >
                    Finalizar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </FormContainer>
      <Notification
        visible={notification.visible}
        type={notification.type}
        message={notification.message}
      />
    </>
  );
};
