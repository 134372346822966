import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Typography } from "@mui/material";
import { Card } from "../../../common/components/Card";
import { Loading } from "../../../common/components/Loading";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = ({ title, data, options }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <Card>
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      {isLoading ? <Loading /> : <Pie options={options} data={data} />}
    </Card>
  );
};
