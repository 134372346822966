import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import {
  BackDrop,
  SidebarContainer,
  SidebarHeader,
  SidebarFooter,
  Menu,
  MenuItems,
  TextItems,
} from "./StyledComponents";

import { Header } from "../Header";
import { Notification } from "../../common/components/Notification";
import { verificaLogout } from "../../services/api";
import { logout } from "../../services/auth";

import MenuIcon from "@mui/icons-material/Menu";
import EventIcon from "@mui/icons-material/Event";
import PeopleIcon from "@mui/icons-material/People";
import BarChartOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import PersonIcon from "@mui/icons-material/Person";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import { decryptUser } from "../../services/auth";

export const Sidebar = () => {
  const navigate = useNavigate();
  const user = decryptUser();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);

  const [notification, setNotification] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const showNotification = (message, type) => {
    setNotification({ message, type, visible: true });
    setTimeout(() => {
      setNotification({ message: "", type: "", visible: false });
    }, 4000);
  };

  const handleSidebarToggle = () => {
    if (isMobile) {
      setOpen(!open);
    }
  };

  async function handleLogout() {
    try {
      const res = await verificaLogout();
      if (res.status === 200) {
        logout();
        navigate("/login");
      }
    } catch (error) {
      showNotification(error.response?.data?.error, "error");
    }
  }

  const menuItems = [
    {
      name: "Agendamentos",
      route: "/",
      icon: <EventIcon />,
    },
    {
      name: "Pacientes",
      route: "/pacientes",
      icon: <PeopleIcon />,
    },
  ];

  if (user && user.nivelAcesso <= 1) {
    menuItems.push({
      name: "Dashboards",
      route: "/dashboards",
      icon: <BarChartOutlinedIcon />,
    });
  }

  const menuItemsAdmin = [
    {
      name: "Usuários",
      route: "/usuarios",
      icon: <PersonIcon />,
    },
  ];

  return (
    <>
      <BackDrop isOpen={open} />
      <Header />
      <SidebarContainer
        open={open}
        onMouseEnter={!isMobile ? () => setOpen(true) : null}
        onMouseLeave={!isMobile ? () => setOpen(false) : null}
      >
        <SidebarHeader>
          <Menu isOpen={open} onClick={handleSidebarToggle}>
            <MenuIcon />
          </Menu>
          {menuItems.map((obj) => (
            <MenuItems
              isOpen={open}
              key={obj.name}
              component={Link}
              onClick={() => navigate(obj.route)}
              to={obj.route}
            >
              {obj.icon}
              <TextItems isOpen={open}>{obj.name}</TextItems>
            </MenuItems>
          ))}
        </SidebarHeader>
        <SidebarFooter>
          {user &&
            user.nivelAcesso <= 1 &&
            menuItemsAdmin.map((obj) => (
              <MenuItems
                isOpen={open}
                key={obj.name}
                component={Link}
                onClick={() => navigate(obj.route)}
                to={obj.route}
              >
                {obj.icon}
                <TextItems isOpen={open}>{obj.name}</TextItems>
              </MenuItems>
            ))}
          <MenuItems
            isOpen={open}
            component={Link}
            onClick={() => handleLogout()}
          >
            <LogoutOutlinedIcon />
            <TextItems isOpen={open}>Logout</TextItems>
          </MenuItems>
        </SidebarFooter>
      </SidebarContainer>
      <Outlet />

      <Notification
        visible={notification.visible}
        type={notification.type}
        message={notification.message}
      />
    </>
  );
};
