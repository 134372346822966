import React from "react";
import Modal from "react-modal";
import { Box, Tooltip, IconButton } from "@mui/material";
import { Title } from "./../../../common/components/Title";
import { List } from "../../../common/components/List";
import { format } from "date-fns";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";

Modal.setAppElement("#root");

export const ModalConsultas = ({ isOpen, onRequestClose, consultas }) => {
  const columns = [
    { field: "created_at", headerName: "Data", headerAlign: "center", flex: 1 },
    {
      field: "usuario_nome",
      headerName: "Médico",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "acao",
      headerName: "Ação",
      headerAlign: "center",
      flex: 1,
      renderCell: (cellValues) => {
        const detalhesConsulta = JSON.stringify(
          cellValues?.row.detalhes_consulta
        );

        const handleClick = () => {
          localStorage.setItem("detalhesConsulta", detalhesConsulta);
          localStorage.setItem("medico", cellValues?.row.usuario_nome);
          localStorage.setItem("data", cellValues?.row.created_at);

          window.open("/detalhes-consulta", "_blank", "noopener,noreferrer");
        };
        return (
          <>
            <Box sx={{ marginRight: "auto", marginLeft: "auto" }}>
              <Tooltip title="Detalhes da Consulta">
                <IconButton size="small" onClick={handleClick}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  const rows = consultas.map((item) => ({
    id: item.id,
    created_at: format(new Date(item.created_at), "dd/MM/yyyy HH:mm"),
    usuario_nome: item.usuario.nome,
    detalhes_consulta: item.detalhes_consulta[0],
  }));

  const handleClose = () => {
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Agendamento Formulário"
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 1000,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          marginRight: "-25vw",
          width: "80vw",
          maxWidth: "1200px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title>Consultas do Paciente</Title>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          marginLeft: "-60px",
        }}
      >
        <List rows={rows} columns={columns} />
      </Box>
    </Modal>
  );
};
