import styled from "styled-components";
import { color } from "../../../styles/theme/colors/colors";
import background from "../../../assets/background.webp";
import logo from "../../../assets/logo.webp";

export const BackgroudImage = styled.div`
  background: ${color.neutral_white};
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin: 0 auto;
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  min-width: 320px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20%;
`;

export const BoxForm = styled.form`
  margin: 40px;
`;

export const Logo = styled.div`
  margin: 10px;
  background: url(${logo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 12vh;
  width: 100%;
  max-width: 300px;
`;

export const BoxFooter = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: flex-end;
  justify-self: end;
  margin-bottom: 33px;
  gap: 25px;
`;
