import styled from "styled-components";
import { color } from "../../../styles/theme/colors/colors";
import { font } from "../../../styles/theme/font/font";

export const HeaderContainer = styled.div`
  height: 70px;
  background-color: ${color.neutral_white};
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  padding-left: 65px;
  width: 100%;
  color: ${color.neutral_white};
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    max-height: 50px;
    width: auto;

    @media (max-width: 768px) {
      max-height: 40px;
    }

    @media (max-width: 480px) {
      max-height: 35px;
    }
  }
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
`;

export const HeaderName = styled.h3`
  color: rgba(0, 0, 0, 0.87);
  font-size: ${font.size_xs};
  font-weight: ${font.weight_regular};
`;
