import { combineReducers } from "redux";
import agendamentosReducer from "./sistema/showAgendamentosReducer";
import agendamentoReducer from "./sistema/showAgendamentoReducer";
import consultasReducer from "./sistema/showConsultasReducer";
import consultasPacienteReducer from "./sistema/showConsultasPacienteReducer";
import consultaReducer from "./sistema/showConsultaReducer";
import pacientesReducer from "./sistema/showPacientesReducer";
import pacienteReducer from "./sistema/showPacienteReducer";
import usuariosReducer from "./sistema/showUsuariosReducer";
import usuarioReducer from "./sistema/showUsuarioReducer";
import antecedentesPessoaisReducer from "./dashboards/showAntecedentesPessoaisReducer";
import condicoesOptoReducer from "./dashboards/showCondicoesOptoReducer";
import consultasMesReducer from "./dashboards/showConsultasMesReducer";
import medicamentosReducer from "./dashboards/showMedicamentosReducer";
import sintomasReducer from "./dashboards/showSintomasReducer";
import tipoLenteReducer from "./dashboards/showTipoLenteReducer";

export default combineReducers({
  agendamentosReducer,
  agendamentoReducer,
  consultasReducer,
  consultasPacienteReducer,
  consultaReducer,
  pacientesReducer,
  pacienteReducer,
  usuariosReducer,
  usuarioReducer,
  antecedentesPessoaisReducer,
  condicoesOptoReducer,
  consultasMesReducer,
  medicamentosReducer,
  sintomasReducer,
  tipoLenteReducer,
});
